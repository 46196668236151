<template>
  <div>
    <div class="top-bar-secondary pt-1 pb-5">
      <div class="columns mt-2 is-vcentered is-mobile">
        <div class="column">
          <span v-if="orderDetail" class="is-bold">
            {{ orderDetail.projectName ? orderDetail.projectName + ": " : "" }}
            {{ orderDetail.service.name }}
          </span>
        </div>
      </div>
    </div>
    <div id="app" class="app-body">
      <div class="p-3">
        <div class="columns">
          <div class="column">
            <div class="spacer-small"></div>
            <div class="columns is-vcentered">
              <div class="column">
                <router-link
                  :to="{ name: 'VendorDashboard' }"
                  class="is-dark-gray"
                >
                  <b-icon
                    icon="arrow-left-thin-circle-outline"
                    size="is-medium"
                    class="mb-4"
                  />
                </router-link>
                <p class="is-subtitle is-secondary is-bold">Order Details</p>
              </div>
            </div>
            <div class="spacer-small"></div>
            <div v-if="loading">
              <Loading />
            </div>
            <div v-else>
              <div v-if="orderDetail.status === 'completed'">
                <b-message type="is-info">
                  <i class="mdi mdi-check mr-1"></i>
                  Order Completed
                  <p
                    v-if="
                      orderDetail.selectedDeliverable &&
                      orderDetail.selectedDeliverable != ''
                    "
                  >
                    <i class="mdi mdi-information-outline mr-1"></i>
                    Selected Deliverable: {{ orderDetail.selectedDeliverable }}
                  </p>
                </b-message>
                <div class="spacer-small"></div>
              </div>
              <div class="box-outline p-10">
                <div class="columns is-vcentered">
                  <div class="column">
                    <p class="is-subtitle-small is-dark-gray is-bold">
                      Customer
                    </p>
                    <p>
                      {{
                        orderDetail.customer.firstName +
                        " " +
                        orderDetail.customer.lastName
                      }}
                    </p>
                    <p>
                      {{ orderDetail.customer.email }}
                    </p>
                    <p>
                      {{ orderDetail.customer.contact }}
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      @click="openResponsesModal()"
                      type="is-info"
                      icon-left="file"
                    >
                      Show Responses
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="box-outline p-10 mt-4">
                <p class="is-subtitle-small is-dark-gray is-bold">
                  Revisions Requested
                </p>
                <hr class="mb-0" />
                <div v-if="revisions.length">
                  <div v-for="(revision, index) in revisions" :key="index">
                    <b-message type="is-info" has-icon class="mt-4">
                      <div class="columns is-vcentered">
                        <div class="column">
                          <p class="subtitle-small is-bold">Revision</p>
                          {{ revision.text }}
                          <p v-if="revision.file" class="text-small">
                            <a :href="revision.file">
                              Download attached file ({{
                                revision.file.split("/")[
                                  revision.file.split("/").length - 1
                                ]
                              }})
                            </a>
                          </p>
                        </div>
                      </div>
                    </b-message>
                  </div>
                </div>
                <div v-else class="mt-4">
                  <i class="mdi mdi-information-outline mr-1"></i>
                  No Revisions requested yet
                </div>
              </div>
              <div class="box-outline p-10 mt-4">
                <div v-if="deliverables.length">
                  <p class="is-subtitle-small is-dark-gray is-bold">
                    Deliverables
                  </p>
                  <hr class="mb-0" />
                  <b-table class="mt-4" :data="deliverables" :hoverable="true">
                    <b-table-column label="Filename" v-slot="props">
                      {{
                        props.row.file.split("/")[
                          props.row.file.split("/").length - 1
                        ]
                      }}
                    </b-table-column>
                    <b-table-column label="Date" v-slot="props">
                      {{ props.row.date | date }}
                    </b-table-column>
                    <b-table-column label="Action" v-slot="props">
                      <a :href="props.row.file">
                        <i class="mdi mdi-cloud-download-outline pr-2"></i>
                        <span>Download</span>
                      </a>
                    </b-table-column>
                  </b-table>
                </div>
                <div v-else>
                  <div class="columns is-vcentered">
                    <div class="column">
                      <p class="is-secondary is-subtitle-small is-bold">
                        No Deliverables uploaded
                      </p>
                      <p>
                        Deliverables will appear here once they are uploaded
                      </p>
                    </div>
                    <div class="column is-narrow has-text-centered">
                      <img
                        src="@/assets/img/home/about_company_illustration.svg"
                        width="120px"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  v-if="orderDetail.status !== 'completed'"
                  class="columns is-vcentered"
                >
                  <div class="column is-narrow">
                    <b-button
                      @click="openDeliverableUploadModal()"
                      type="is-info"
                      icon-left="upload"
                      >Upload Deliverable</b-button
                    >
                  </div>
                </div>
              </div>
              <ChatBox
                v-if="orderDetail.status !== 'completed'"
                prespective="vendor"
              />
              <div
                v-if="false"
                id="messenger"
                class="box-outline-square mt-4"
                style="height: 80vh"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals Start -->
    <b-modal v-model="showDeliverableModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="is-subtitle-small is-dark-gray is-bold">Task Delivery</p>
        </header>

        <section class="modal-card-body has-text-centered">
          <p>Please upload your completed tasks in .pdf format.</p>
          <b-field class="mt-4">
            <b-upload v-model="dropFiles" drag-drop multiple>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span
              v-for="(file, index) in dropFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Cancel" @click="closeDeliverableUploadModal" />
          <b-button
            type="is-info"
            :disabled="isSubmitting"
            @click="submitDeliverable"
          >
            {{ isSubmitting ? "Submitting" : "Submit" }}
          </b-button>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="showResponsesModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="is-subtitle-small is-dark-gray is-bold">
            Submitted Responses
          </p>
        </header>
        <section v-if="responses" class="modal-card-body">
          <div v-for="(response, index) in responses" :key="index">
            <p class="is-bold is-secondary">{{ response.q }}</p>
            <p
              v-if="
                typeof response.r === 'string' &&
                response.r.slice(0, 5) == 'FILE#'
              "
              class="mt-2 mb-4"
            >
              <a
                v-if="response.r != 'FILE#undefined'"
                :href="response.r.split('#')[1]"
              >
                <b-button icon-left="download"> Download File </b-button>
              </a>
              <a v-else href="#" class="has-text-info">
                <i class="mdi mdi-information-outline mr-1"></i>
                No file uploaded
              </a>
            </p>
            <p
              v-else-if="
                typeof response.r === 'string' &&
                response.r.slice(0, 4) == 'IMG#'
              "
              class="mt-2 mb-4 has-text-centered"
            >
              <ResponseImageViewer :image="response.r.split('#')[1]" />
            </p>
            <p
              v-else-if="
                Array.isArray(response.r) &&
                response.r.length &&
                response.r[0].slice(0, 4) == 'IMG#'
              "
              class="mt-2 mb-4 has-text-centered"
            >
              <ResponseImageViewer
                v-for="(response, index) in response.r"
                :key="index"
                :image="response.split('#')[1]"
              />
            </p>
            <p
              v-else-if="
                Array.isArray(response.r) &&
                response.r.length &&
                response.r[0].slice(0, 5) == 'FILE#'
              "
              class="mt-2 mb-4"
            >
              <a
                v-for="(response, index) in response.r"
                :key="index"
                :href="
                  response.r[0] != 'FILE#undefined'
                    ? response.split('#')[1]
                    : '#'
                "
              >
                <b-button
                  v-if="response.r[0] != 'FILE#undefined'"
                  icon-left="download"
                >
                  Download File
                </b-button>
                <p v-else>
                  <i class="mdi mdi-information-outline mr-1"></i> No file
                  uploaded
                </p>
              </a>
            </p>
            <p
              v-else-if="Array.isArray(response.r) && response.r.length"
              class="mt-2 mb-4"
            >
              {{ response.r.join(", ") }}
            </p>
            <p
              v-else-if="
                typeof response.r === 'string' &&
                response.r.slice(0, 4) == 'IMG#'
              "
              class="mt-2 mb-4"
            >
              <ResponseImageViewer :image="response.r.split('#')[1]" />
            </p>
            <p v-else class="mb-4">{{ response.r }}</p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="closeResponsesModal" />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";
import ChatBox from "../../components/ChatBox.vue";
import ResponseImageViewer from "../../components/misc/ResponseImageViewer.vue";

export default {
  name: "OrderDetail",
  components: { Loading, ChatBox, ResponseImageViewer },
  data() {
    return {
      loading: false,
      isSubmitting: false,
      showDeliverableModal: false,
      showResponsesModal: false,
      dropFiles: [],
    };
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    orderDetail() {
      return this.$store.state.orderDetail;
    },
    deliverables() {
      return this.$store.state.orderDetail
        ? this.$store.state.orderDetail.deliverables
        : undefined;
    },
    revisions() {
      return this.$store.state.orderDetail
        ? this.$store.state.orderDetail.revisions
        : undefined;
    },
    responses() {
      if (this.$store.state.orderDetail) {
        const questions = Object.values(
          this.$store.state.orderDetail.questions
        );
        const responses = Object.values(
          this.$store.state.orderDetail.responses
        );
        const res = [];
        for (let i = 0; i < questions.length; i++) {
          const pair = {
            q: questions[i] || undefined,
            r: responses[i] || undefined,
          };
          res.push(pair);
        }
        return res;
      } else {
        return undefined;
      }
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("getVendorOrderDetail", this.orderId);
    this.loading = false;

    // eslint-disable-next-line no-undef
    // const weavy = new Weavy({
    //   url: "https://brandeeq.weavy.io",
    //   jwt: () =>
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnZXQud2VhdnkuaW8iLCJzdWIiOjE5MzQsImRpciI6IlNhbmRib3giLCJnaXZlbl9uYW1lIjoiR2VvcmdlICIsImZhbWlseV9uYW1lIjoiTm9vbiIsImVtYWlsIjoiaHJAYnJhbmRlZXEuY29tIiwiZXhwIjoxNjU0MDI5NjQ3fQ.Y4Z8AJb6TAL5iyVpzNQrL6OlURlg-tTXIVHgizmUM_s",
    // });

    // // eslint-disable-next-line no-unused-vars
    // const messenger = weavy.app({
    //   id: "123",
    //   type: "messenger",
    //   container: "#messenger",
    // });
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    openDeliverableUploadModal() {
      this.showDeliverableModal = !this.showDeliverableModal;
      this.orderSelected = this.orderId;
    },
    openResponsesModal() {
      this.showResponsesModal = true;
    },
    closeResponsesModal() {
      this.showResponsesModal = false;
    },
    closeDeliverableUploadModal() {
      this.showDeliverableModal = false;
    },
    async submitDeliverable() {
      this.isSubmitting = true;
      const metadata = JSON.stringify({ orderId: this.orderId });
      const request = new FormData();
      request.append("file", this.dropFiles[0]);
      request.append("metadata", metadata);
      await this.$store.dispatch("uploadDeliverable", request);
      this.closeDeliverableUploadModal();
      this.isSubmitting = false;
      this.loading = true;
      await this.$store.dispatch("getVendorOrderDetail", this.orderId);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
